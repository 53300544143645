/* eslint-disable no-console,no-undef, camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import FeaturedChallenges from '../FeaturedChallenges';
import ChallengeForm from "./ChallengeForm";
import Waiting from '../Waiting';

import { Layout, FormContainer } from '../CreateEvent/styles';
import { ChallengeTitle, FeaturedEventsContainer } from '../CreateChallenge/styles';
import BehaviorForm from '../BehaviorChallenge/BehaviorForm';
import { convertDateInTimezone } from '../../utils/methods';
import {getChallengeDetails, getSurveyLibraries} from "../../redux/actions";
import { withTranslation } from 'react-i18next';

class EditChallenge extends React.Component {
  constructor() {
    super();
    this.state = {
      survivorChallengeData: null,
      challengeId: null
    }
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    const { challenge } = prevProps;
    const challengeData = {...challenge};
    if(challengeData) {
      this.setState({
        survivorChallengeData: {
          title: _.get(challengeData, 'title', ''),
          date: moment.utc(_.get(challengeData, 'start_date')).local().format('MM/DD/YYYY'),
          endDate: moment.utc(_.get(challengeData, 'end_date')).local().format('MM/DD/YYYY'),
          description: _.get(challengeData, 'body', ''),
          time: moment(_.get(challengeData, 'start_date', moment())).format('hh:mm a'),
          endTime: moment(_.get(challengeData, 'end_date', moment())).format('hh:mm a'),
          activeDate: moment.utc(_.get(challengeData, 'start_date')).format('MM/DD/YYYY hh:mm a'),
          finishDate: moment.utc(_.get(challengeData, 'end_date')).format('MM/DD/YYYY hh:mm a'),
          activityType: _.get(challengeData, 'event_type', 'Total Distance'),
          challengePointValue: _.get(challengeData, 'challenge_point', '5'),
          challengeMode: _.get(challengeData, 'challenge_mode', 'Team'),
          weeklyIncrement: _.get(challengeData, 'weekly_increment', 0),
          survivorAverage: _.get(challengeData, 'survivor_average', 0),
          imageName: _.get(challengeData, 'imageName', ''),
          imgSrc: _.get(challengeData, 'image', ''),
          companies: challengeData &&  challengeData.challenge_companies && challengeData.challenge_companies.split(',').map((value) => (
            parseInt(value,10)
          )),
          locations: challengeData.challenge_city_states && challengeData.challenge_city_states.split(',').map((value) => (
            parseInt(value,10)
          )),
          isSpouse: challengeData.spouse,
          isDependent: challengeData.dependent,
          isEmployee: !(challengeData.is_spouse_or_dependent),
          "launch_challenge_email": _.get(challengeData, "launch_challenge_email", 0),
          "challenge_reminder_email": _.get(challengeData,"challenge_reminder_email",0),
          "challenge_complete_email": _.get(challengeData,"challenge_complete_email",0),
          surveyId: challengeData.survey_id
        }
      });
    }
  }

  componentDidMount() {
    const { history, challenge, getChallengeDetails, getSurveyLibraries} = this.props;
    const { pathname } = history.location;
    const arr = pathname.split('/');
    getChallengeDetails(arr[arr.length - 2]);
    getSurveyLibraries();
    if(!challenge) {  
      history.push(`/challenges/${arr[arr.length-2]}`);
    } 
    else {
      this.setState({
        challengeId: parseInt(arr[arr.length-2],10)
      })
    }
  }

  render() {
    const { createChallenge, isLoading, role, challenge, history, surveyLibraries} = this.props;
    const MandatoryFields = ['title', 'imgSrc', 'activityType', 'description', 'challengePointValue', 'companies'];

    if(isLoading || !challenge || !this.state.survivorChallengeData) {
      return <Waiting />
    }
    let behaviorData = {}
    let survivorData = {}
    if(challenge.challenge_type === 'behavior') {
      let startDay = convertDateInTimezone(challenge.start_date)._d;
      let endDay = convertDateInTimezone(challenge.end_date)._d;
      let totalLength = moment(endDay).diff(startDay, 'days');
      let totalWeek = 0;
      if(totalLength > 0) {
        totalWeek = parseInt(Math.ceil(totalLength/7),10);
      }

      behaviorData = {
        "title": challenge.title,
        "activityType": challenge.event_type,
        "imgSrc":  _.get(challenge, 'image', ''),
        "imageName": _.get(challenge, 'imageName', ''),
        "challengePointValue": challenge.challenge_point,
        "description": challenge.body,
        "challengeType": "behavior",
        "isBehaviorChallenge": true,
        "challengeLength": totalWeek,
        "behaviorArr": challenge.behavior_challenge_details,
        "date": moment(startDay).format('MM/DD/YYYY'),
        "activeDate": this.state.survivorChallengeData.activeDate,
        "finishDate": this.state.survivorChallengeData.finishDate,
        "behavior_challenge_id": challenge.behavior_challenge_id,
        "isSpouse": this.state.survivorChallengeData.isSpouse,
        "isDependent": this.state.survivorChallengeData.isDependent,
        "isEmployee": this.state.survivorChallengeData.isEmployee,
        "companies": challenge.challenge_companies.split(',').map((value) => (
          parseInt(value,10)
        )),
        "locations": challenge.challenge_city_states && challenge.challenge_city_states.split(',').map((value) => (
          parseInt(value,10)
        )),
        "launch_challenge_email": _.get(challenge, "launch_challenge_email", 0),
        "challenge_reminder_email": _.get(challenge,"challenge_reminder_email",0),
        "challenge_complete_email": _.get(challenge,"challenge_complete_email",0),
        surveyId: _.get(challenge,"survey_id", null)
      }
    }
    if(challenge.challenge_type === 'survivor' || challenge.challenge_type === 'group' || challenge.challenge_type === 'new_group') {
      let startDay = convertDateInTimezone(challenge.start_date)._d;
      let endDay = convertDateInTimezone(challenge.end_date)._d;
      let totalLength = moment(endDay).diff(startDay, 'days');
      let totalWeek = 0;
      if(totalLength > 0) {
        totalWeek = parseInt(Math.ceil(totalLength/7),10);
      }
      survivorData = {...this.state.survivorChallengeData, challengeLength: totalWeek}
    }
    return (
      <Layout>
        <ChallengeTitle>{this.props.t("Edit Challenge")}</ChallengeTitle>
        { challenge.challenge_type === 'behavior'
          ?
          <FormContainer editChallenge={1}>
            <BehaviorForm 
              history={this.props.history}
              survivorChallengeData={behaviorData}
              createChallenge={createChallenge}
              role={role}
              challengeId={challenge.id}
              isEditChallenge={true}
              surveyLibraries={surveyLibraries}
            />
          </FormContainer>
          :
          <FormContainer editChallenge={challenge.challenge_type !== 'group'}>
            <ChallengeForm
              isSurvivorChallenge={challenge.challenge_type === 'survivor'}
              isGroupChallenge={challenge.challenge_type === 'new_group'}
              history={this.props.history}
              MandatoryFields={MandatoryFields}
              createChallenge={createChallenge}
              survivorChallengeData={survivorData}
              role={role}
              challengeId= {this.state.challengeId}
              surveyLibraries={surveyLibraries}
            />
          </FormContainer>
        }
        { (challenge.challenge_type === 'group' || challenge.challenge_type === 'behavior') && challenge.challenge_type !== 'behavior' &&
          <FeaturedEventsContainer>
            <FeaturedChallenges history={history}/>
          </FeaturedEventsContainer>
        }
      </Layout>
    );
  }
}

EditChallenge.propTypes = {
  history: PropTypes.object,
  createChallenge: PropTypes.func.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  challenge: PropTypes.object,
  role: PropTypes.string.isRequired,
  getChallengeDetails: PropTypes.func,
  surveyLibraries:PropTypes.array,
  getSurveyLibraries:PropTypes.func,
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  challenge: state.challenges.challengeDetails,
  surveyLibraries: state.surveys.surveyLibraries,
});
const mapDispatchToProps = (dispatch) => ({
  getChallengeDetails: (id) => dispatch(getChallengeDetails(id)),
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(EditChallenge));